
import {
    createVNode
} from 'vue';
import {
    ExclamationCircleOutlined,
    DeleteOutlined,
    UploadOutlined
} from '@ant-design/icons-vue';
import OrderUpload from "../order/compontent/order_upload.vue"
import Logs from './compontent/logs.vue';

export default {
    name: "OrderImportTask",
    components: {
        DeleteOutlined,
        UploadOutlined,
        OrderUpload,
        Logs
    },
    data() {
        return {
            url: '/user/order_import_task/list',
            columns: [{
                    key: 'index',
                    width: 48,
                    customRender: ({
                        index
                    }) => this.$refs.table.tableIndex + index
                },
                {
                    title: this.$t('文件名'),
                    dataIndex: 'filename',
                    sorter: true
                },
                {
                    title: this.$t('订单数量'),
                    dataIndex: 'order_count',
                    sorter: true,
                    width: 150
                },
                {
                    title: this.$t('已导入数量'),
                    dataIndex: 'imported_order_count',
                    sorter: true,
                    width: 150
                },
                {
                    title: this.$t('状态'),
                    dataIndex: 'status',
                    width: 100,
                    sorter: true,
                    slots: {
                        customRender: 'status'
                    }
                },
                {
                    title: this.$t('结束时间'),
                    dataIndex: 'end_time',
                    width: 200,
                    sorter: true
                },
                {
                    title: this.$t('share.created'),
                    dataIndex: 'created',
                    width: 200,
                    sorter: true
                },
                {
                    title: this.$t('share.operation'),
                    key: 'action',
                    width: 120,
                    align: 'center',
                    slots: {
                        customRender: 'action'
                    }
                }
            ],
            // 表格搜索条件
            where: {},
            // 表格选中数据
            selection: [],
            // 当前编辑数据
            current: null,
            // 是否显示导入弹窗
            showImportModal: false,
            // 是否显示日志
            showLogs: false
        };
    },
    methods: {
        /* 搜索 */
        reload() {
            this.selection = [];
            this.$refs.table.reload({
                page: 1
            });
        },
        /*  重置搜索 */
        reset() {
            this.where = {};
            this.$nextTick(() => {
                this.reload();
            });
        },
        /* 打开日志弹窗 */
        openLogs(row) {
            this.current = row;
            this.showLogs = true;
        },
        /* 删除单个 */
        remove(row) {
            const hide = this.$message.loading('share.requesting', 0);

            this.$http.post('/user/order_import_task/delete/', {
                ids: [row.id]
            }).then(res => {
                hide();
                if (res.data.code === 0) {
                    this.$message.success(res.data.msg);
                    this.reload();
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                hide();
                this.$message.error(e.message);
            });
        },
        /* 批量删除 */
        removeBatch() {
            if (!this.selection.length) {
                return this.$message.error('share.select_one_more_then');
            }

            this.$confirm({
                title: this.$t('share.tips'),
                content: this.$t('share.delete_confirm'),
                icon: createVNode(ExclamationCircleOutlined),
                maskClosable: true,
                onOk: () => {
                    const hide = this.$message.loading('share.requesting', 0);

                    this.$http.post('/user/order_import_task/delete/', {
                        ids: this.selection.map(d => d.id)
                    }).then(res => {
                        hide();
                        if (res.data.code === 0) {
                            this.$message.success(res.data.msg);
                            this.reload();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(e => {
                        hide();
                        this.$message.error(e.message);
                    });
                }
            });
        }
    }
}
